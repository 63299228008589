import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, ButtonToolbar, Loader } from "rsuite";

import StockoutInventoryBarGraph from "../../../components/inventory/stockoutInventoryBarGraph";
import StockoutInventoryLineGraph from "../../../components/inventory/stockoutInventoryLineGraph";
import StockoutInventoryTable from "../../../components/inventory/stockoutInventoryTable";
import { STOCKOUT_INV_TABLE } from "../../../constants/dataConstant";
import { useLocation } from "react-router-dom";
import {
  getDemandInventoryLineGraphData,
  getHighLowDOSTrendChart,
  getPotentialOverstockLineGraphData,
  getStockoutChartData,
} from "../../../queries/apiQueries";
import moment from "moment";
import { useSelector } from "react-redux";
import HighLowDOSTrendLineGraph from "../../../components/inventory/highLowDOSTrendLineGraph";
import OverstockWaterFallGraph from "../../../components/inventory/overstockWaterFallGraph";

const COLOR = ["#57A4AD", "#ffffff", "#cecfd0", "#007090"];

const StockoutInventoryPage = (props: any) => {
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [lineGraphData, setLineGraphData] = useState<any>(null);
  const [lineGraphRepData, setLineGraphRepData] = useState<any>(null);
  const [lineGraphData1, setLineGraphData1] = useState<any>(null);
  const [referenceLine, setReferenceLine] = useState(0);
  const [barGraphData, setBarGraphData] = useState(null);
  const [TileFilter, setTileFilter] = useState<string[]>([]);

  const [highDOSLineGraph, setHighDOSLineGraph] = useState(null);
  const selectedGBPFilter = useSelector(
    (state: any) => state.CommonReducer.selectedGBPFilter
  );

  const type = new URLSearchParams(location.search).get("type");

  const getStockoutChartDataHandler = () => {
    getStockoutChartData({ ...selectedGBPFilter,  alertType: Number(type),releaseType: TileFilter })
      .then((response) => {
        if (response?.status === 200) {
          let final = [],
            final1 = [],
            key: any = [];
          for (let i = 3; i >= 1; i--) {
            let obj: any = {
              name: `Stockout Days M-${i}`,
            };
            response?.data?.stockOut?.forEach((value: any, index: any) => {
              let segment = value?.Segmentation?.split(".")[0];
              if (segment === "1B" || segment === "1C") {
                segment = "B";
              }
              obj[segment] = value[`Stockout Days M-${i}`];
              if (i === 1) {
                key.push({
                  key: segment,
                  color: COLOR[index],
                });
              }
            });
            final.push(obj);
          }

          for (let i = 1; i <= 3; i++) {
            let obj: any = {
              name: `LostSales $ M-${i}`,
            };
            response?.data?.lostSale?.forEach((value: any) => {
              obj[value?.Future_ABC_Segmentation] = value[`LostSales $ M-${i}`];
            });
            final1.push(obj);
          }

          let dohm1Sum = 0;
          let dohm2Sum = 0;
          let dohm3Sum = 0;
          response?.data?.stockOut?.forEach((value: any) => {
            dohm1Sum += value.dohm1;
            dohm2Sum += value.dohm2;
            dohm3Sum += value.dohm3;
          });
          final[0].dohm = dohm3Sum;
          final[1].dohm = dohm2Sum;
          final[2].dohm = dohm1Sum;

          const ids = key.map((id: any) => id?.key);
          let finalKey = key.filter(
            (id: any, index: any) => !ids.includes(id?.key, index + 1)
          );
          response.data.stockOut = final;
          response.data.lostSale = final1;
          response.data.key = finalKey;
          setBarGraphData(response?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDemandInventoryLineGraphDataHandler = () => {
    getDemandInventoryLineGraphData({
      ...selectedGBPFilter,
      alertType:
        type === "0"
          ? "stockout"
          : type === "1"
          ? "potentialStockout"
          : "lowDos",
      releaseType: TileFilter,
    })
      .then((response: any) => {
        if (response?.status === 200) {
          response?.data?.demand?.forEach((value: any, index: number) => {
            value.date = moment(value?.dayDate?.slice(0, 10)).format("MMM'YY");
          });
          response?.data?.inventory?.forEach((value: any) => {
            value.date = moment(value?.monthdate?.slice(0, 10)).format(
              "MMM'YY"
            );
          });
          response?.data?.repData?.forEach((value: any) => {
            value.date = moment(value?.monthdate?.slice(0, 10)).format(
              "MMM'YY"
            );
          });

          setLineGraphData(response?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPotentialOverstockLineGraphDataHandler = () => {
    getPotentialOverstockLineGraphData(selectedGBPFilter)
      .then((response: any) => {
        if (response?.status === 200) {
          // let finalData = [
          //   {
          //     name: "Current Inventory",
          //     value: response?.data?.data[0]?.onHand,
          //   },
          //   {
          //     name: "Inv at the end of LT1",
          //     value: response?.data?.data[0]?.invatendoflt,
          //   },
          //   {
          //     name: "Inv at the end of LT2",
          //     value: response?.data?.data[0]?.invatendoflt2,
          //   },
          // ];
          const formattedData = response?.data?.data.map(
            (item: any, index: number) => {
              const date = moment(item.monthstartdate.slice(0, 10)).format(
                "MMM YY"
              );
              if (index === response?.data?.data.length - 1) {
                return { ...item, date: "Inv At End LT 2" };
              } else if (index === response?.data?.data.length - 2) {
                return { ...item, date: "Inv At End LT 1" };
              } else {
                return { ...item, date };
              }
            }
          );
          response?.data?.repData?.forEach((value: any) => {
            value.date = moment(value?.monthdate?.slice(0, 10)).format(
              "MMM'YY"
            );
          });
          setLineGraphData1(formattedData);
          setReferenceLine(response?.data?.data[0]?.effictivess);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getHighLowDOSTrendChartHandler = () => {
    getHighLowDOSTrendChart({ ...selectedGBPFilter,  alertType: Number(type) === 2 ? 'lowDos' : 'highDos' , releaseType: TileFilter})
      .then((response: any) => {
        if (response?.status === 200) {
          console.log(response?.data?.data, "val");
          response?.data?.data?.forEach((value: any, index: number) => {
            value.date = moment(value?.monthstartdate?.slice(0, 10)).format(
              "MMM'YY"
            );
          });
          setHighDOSLineGraph(response?.data?.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (type && Object.keys(selectedGBPFilter).length !== 0) {
      if (type === "0") {
        getStockoutChartDataHandler();
      }
      getDemandInventoryLineGraphDataHandler();
      if (type === "1") {
        getPotentialOverstockLineGraphDataHandler();
      }
      if (type === "2") {
        getHighLowDOSTrendChartHandler();
      }
    }
  }, [type, selectedGBPFilter, TileFilter]);

  const handleTileClick = (value: string) => {
    if (TileFilter.length > 0 && TileFilter[0] === value) {
      // If the clicked tile is the same as the currently selected tile, clear the filter
      setTileFilter([]);
    } else {
      // Otherwise, set the filter to the clicked tile
      setTileFilter([value]);
    }
  };

  return (
    <>
      <div className="flex justify-center items-center">
        <div className="w-[95%] grid grid-cols-1 gap-4">
          <div className="grid grid-cols-7 gap-4">
            <div className="grid grid-cols-3 gap-4 justify-items-end col-span-2"></div>
            <div className="col-span-3">
              <p className="text-mainGreen text-1.5vw text-center ">
                {type === "0"
                  ? `High Stockout
(Last 3 months)`
                  : type === "1"
                  ? `Potential Lost Service
(End of 2nd Lead Time)`
                  : `Low Days of Inventory
(Last 4 Months)`}
              </p>
            </div>
         {type !== '0' && (  <div className="grid grid-cols-3 items-center text-center mt-2  gap-2 justify-items-center col-span-2">
              <div>
                <p className="text-white font-bold text-lg">Planning :</p>
              </div>
              {["Auto", "Manual"].map((tile, index) => (
                <div
                  key={index}
                  onClick={() => handleTileClick(tile)}
                  className={`cursor-pointer ${
                    TileFilter.includes(tile) ? "bg-green-600" : "bg-[#2d2d2d]"
                  } w-full p-1 pl-1 text-center rounded-md`}
                >
                  <p className="text-white font-bold text-sm">{tile}</p>
                </div>
              ))}
            </div>)}
          </div>
        </div>
      </div>

      <div className=" ">
        {loading && (
          <Loader backdrop size="md" inverse center content="loading..." />
        )}

        <div className="w-full flex justify-center">
          <div className="w-[95%]">
            <div className=" grid grid-cols-2 gap-6">
              {type === "0" && (
                <StockoutInventoryBarGraph barGraphData={barGraphData} />
              )}
              {type === "0" && (
                <StockoutInventoryLineGraph lineGraphData={lineGraphData} />
              )}
              {type === "1" && (
                // <StockoutInventoryLineGraph
                // lineGraphData={{lineGraphData1:lineGraphData1,repData:lineGraphRepData}}
                // referenceLine={referenceLine}
                // graphType={3}
                // />

                <OverstockWaterFallGraph
                  waterfallData={lineGraphData1}
                  type={1}
                  kkey={1}
                />
              )}
              {type === "1" && (
                <StockoutInventoryLineGraph
                  lineGraphData={lineGraphData}
                  // graphType={2}
                />
              )}
              {type === "2" && (
                <HighLowDOSTrendLineGraph
                  lineGraphData={highDOSLineGraph}
                  graphType={1}
                />
              )}
              {type === "2" && (
                <StockoutInventoryLineGraph
                  lineGraphData={lineGraphData}
                  // graphType={2}
                />
              )}
            </div>
            <div className="w-full flex justify-center items-center">
              <div className="w-full mt-[0.5vw]">
                <StockoutInventoryTable TileFilter={TileFilter} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StockoutInventoryPage;

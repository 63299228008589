import { Card, CardContent } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Loader } from 'rsuite'
import { formatAmount, formatNumber } from '../../utils/commonFunctions';
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { getInventoryReportAlerts } from '../../queries/apiQueries';



function InventoryReports() {
  const [loading, setLoading] = useState(false);
  const [ReportData , setReportData] = useState<any>(({
    Inactive1: { count: 0 },
    Inactive2: { count: 0 },
  }));

  const getInventoryReportData = () =>{
    getInventoryReportAlerts({})
    .then((response) => {
      if (response?.status === 200) {
        let obj: any = new Object();

        response?.data?.data.forEach((value: any) => {
          obj[value?.title] = {
            count: value?.totalCount,
            amount: value?.amount,
          };
        });
        setReportData(obj)
      }
      setLoading(false)
    }).catch((error) => {
      console.log(error);

    });
  }

  useEffect(() => {
    setLoading(true)
    getInventoryReportData();
  }, [])
  

  return (
    <div className="p-2 w-full flex justify-center mt-[1.2dvw]">
    {loading && (
      <Loader size="md" backdrop inverse center content="loading..." />
    )}

    {!loading && (
      <div className="w-[95%] ">
        <div className="w-full border-b-2 border-teal-700"></div>

        <div className="w-full grid grid-cols-3">
          <div>
            <div className="w-full flex justify-center">
              <span className="text-1.1vw text-white border-[1px] border-teal-700 px-2 py-1 top-[-20px] relative bg-black">
                Proactive Overstock Management
              </span>
            </div>

          
          </div>
          <div className="border-l-2 border-teal-700">
            <div className="w-full flex justify-center">
              <span className="text-1.1vw text-white border-[1px] border-teal-700 px-2 py-1 top-[-20px] relative bg-black">
                Insufficient Stockout Management
              </span>
            </div>
            <div className="flex justify-center">
                  <Link
                      to={{
                        pathname: "obsolete-materials",
                        search: `?type=2`,
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Card
                        style={{ backgroundColor: "#000000" }}
                        className="h-[10vw] w-[12.5vw]"
                      >
                        <CardContent className="bg-black border-[#00FFFF] border-[1px] rounded-2xl h-full !pt-[4px]">
                          <div className="w-full h-full grid grid-cols-1">
                            <span
                              className={` text-0.95vw text-center text-teal-400 font-medium`}
                            >
                            Open PO Expedite
                             
                              {/* {element?.alerts.replace(
                                      /([a-z])([A-Z])/g,
                                      "$1 $2"
                                    )} */}
                            </span>
                            <span className="text-white text-1.5vw text-center  font-semibold">
                            {(ReportData["Inactive3"]?.count)}
                            </span>
                            <span className="text-white text-0.95vw text-center  font-semibold">
                            {`Impact ($): $${formatAmount(ReportData["Inactive3"]?.amount)}`}
                            </span>
                            <div className="w-full flex justify-end ">
                              <KeyboardDoubleArrowRightIcon
                                className="text-teal-400"
                                sx={{
                                  fontSize: {
                                    xs: 10,
                                    sm: 20,
                                    md: 30,
                                    lg: 30,
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Link>
                  </div>
       
          </div>

          <div className="border-l-2 border-teal-700">
            <div className="w-full flex justify-center">
              <span className="text-1.1vw text-white border-[1px] border-teal-700 px-2 py-1 top-[-20px] relative bg-black">
                Inactive Inventory Management
              </span>
            </div>
            <div className="w-full relative top-[-1.5vw]">
              <div className="absolute  left-[26vw] mt-[11vw] ">
                <p className="text-white text-0.95vw text-center">
                  Refresh <br /> Frequency
                </p>
                <p className=" text-0.95vw text-white text-center py-1 bg-black px-2  border-2 border-[#00FFFF] ">
                  Weekly
                </p>
                {/* <p className=" text-1.5vw text-white mx-2">/</p> */}
                <p className=" text-0.95vw text-white text-center bg-black px-2 py-1 border-2 border-mainGreen ">
                  Monthly
                </p>
              </div>
              <div className="flex justify-center mt-[1.5vw]">
                    <Link
                      to={{
                        pathname: "obsolete-materials",
                        search: `?type=0`,
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Card
                        style={{ backgroundColor: "#000000" }}
                        className="h-[10vw] w-[12.5vw]"
                      >
                        <CardContent className="bg-black border-mainGreen border-[1px] rounded-2xl h-full !pt-[4px]">
                          <div className="w-full h-full grid grid-cols-1">
                            <span
                              className={` text-0.95vw text-center text-mainGreen font-medium`}
                            >
                              Top Obsolete Materials 
                             
                              {/* {element?.alerts.replace(
                                      /([a-z])([A-Z])/g,
                                      "$1 $2"
                                    )} */}
                            </span>
                            <span className="text-white text-1.5vw text-center  font-semibold">
                            {(ReportData["Inactive1"]?.count)}
                            </span>
                            <span className="text-white text-0.95vw text-center  font-semibold">
                            {`OnHand ($): $${formatAmount(ReportData["Inactive1"]?.amount)}`}
                            </span>
                            <div className="w-full flex justify-end ">
                              <KeyboardDoubleArrowRightIcon
                                className="text-mainGreen"
                                sx={{
                                  fontSize: {
                                    xs: 10,
                                    sm: 20,
                                    md: 30,
                                    lg: 30,
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Link>
                  </div>

                  <div className="flex justify-center mt-[1vw]">
                  <Link
                      to={{
                        pathname: "obsolete-materials",
                        search: `?type=1`,
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Card
                        style={{ backgroundColor: "#000000" }}
                        className="h-[10vw] w-[12.5vw]"
                      >
                        <CardContent className="bg-black border-mainGreen border-[1px] rounded-2xl h-full !pt-[4px]">
                          <div className="w-full h-full grid grid-cols-1">
                            <span
                              className={` text-0.95vw text-center text-mainGreen font-medium`}
                            >
                             Top DNO and Excess Material 
                             
                              {/* {element?.alerts.replace(
                                      /([a-z])([A-Z])/g,
                                      "$1 $2"
                                    )} */}
                            </span>
                            <span className="text-white text-1.5vw text-center  font-semibold">
                            {(ReportData["Inactive2"]?.count)}
                            </span>
                            <span className="text-white text-0.95vw text-center  font-semibold">
                            {`Overstock ($): $${formatAmount(ReportData["Inactive2"]?.amount)}`}
                            </span>
                            <div className="w-full flex justify-end ">
                              <KeyboardDoubleArrowRightIcon
                                className="text-mainGreen"
                                sx={{
                                  fontSize: {
                                    xs: 10,
                                    sm: 20,
                                    md: 30,
                                    lg: 30,
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Link>
                  </div>
                  <div className="flex justify-center mt-[1vw]">
                  <Link
                      to={{
                        pathname: "obsolete-materials",
                        search: `?type=3`,
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Card
                        style={{ backgroundColor: "#000000" }}
                        className="h-[10vw] w-[12.5vw]"
                      >
                        <CardContent className="bg-black border-[#00FFFF] border-[1px] rounded-2xl h-full !pt-[4px]">
                          <div className="w-full h-full grid grid-cols-1">
                            <span
                              className={` text-0.95vw text-center text-teal-400 font-medium`}
                            >
                            Open PO by Velocity Code
                             
                              {/* {element?.alerts.replace(
                                      /([a-z])([A-Z])/g,
                                      "$1 $2"
                                    )} */}
                            </span>
                            <span className="text-white text-1.5vw text-center  font-semibold">
                            {(ReportData["Inactive4"]?.count)}
                            </span>
                            <span className="text-white text-0.95vw text-center  font-semibold">
                            {`Open QTY ($): $${formatAmount(ReportData["Inactive4"]?.amount)}`}
                            </span>
                            <div className="w-full flex justify-end ">
                              <KeyboardDoubleArrowRightIcon
                                className="text-teal-400"
                                sx={{
                                  fontSize: {
                                    xs: 10,
                                    sm: 20,
                                    md: 30,
                                    lg: 30,
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Link>
                  </div>
       
              {/* <div className="flex justify-center mt-[1vw]">
                <Link
                  to={{
                    pathname: "inactive-inventory-managment",
                    search: `?type=2`,
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Card
                    style={{ backgroundColor: "#000000" }}
                    className="h-[10vw] w-[12.5vw]"
                  >
                    <CardContent className="bg-black border-mainGreen border-[1px] rounded-2xl h-full !pt-[4px]">
                      <div className="w-full h-full grid grid-cols-1">
                        <span
                          className={` text-0.95vw text-center text-mainGreen  font-medium`}
                        >
                          Ageing Inactive <br />
                          <span className="text-0.7vw">
                            (Age {">"} 8 Months){" "}
                          </span>
                          {element?.alerts.replace(
                                  /([a-z])([A-Z])/g,
                                  "$1 $2"
                                )}
                        </span>
                        <span className="text-white text-1.5vw text-center  font-semibold">
                          {formatNumber(alertData["Inactive3"]?.count)}
                        </span>
                        <span className="text-white text-0.95vw text-center  font-semibold">
                          Onhand: $
                          {formatAmount(alertData["Inactive3"]?.amount) ||
                            0}
                        </span>
                        <div className="w-full flex justify-end ">
                          <KeyboardDoubleArrowRightIcon
                            className="text-mainGreen "
                            sx={{
                              fontSize: {
                                xs: 10,
                                sm: 20,
                                md: 30,
                                lg: 30,
                              },
                            }}
                          />
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
  )
}

export default InventoryReports
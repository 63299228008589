export const ALERT_TABS = [
    {
        label: "High Future Forecast",
        value: "HighFutureForecast",
    }, {
        label: "Low Future Forecast",
        value: "LowFutureForecast",
    }, {
        label: "Low Forecast Accuracy",
        value: "LowForecastAccuracy",
    }, {
        label: "Consistent Historical Over Forecasting",
        value: "ConsistentHistoricalOverForecasting",
    }, {
        label: "Consistent Historical Under Forecasting",
        value: "ConsistentHistoricalUnderForecasting",
    
    }, {
        label: "High Outlier Correction",
        value: "HighOutlierCorrection",
    }, {
        label: "Volatile Forecast ",
        value: "VolatileForecast",
    }
]

export const STATUS_DATA=[{ label: 'Reviewed', value: 1 },{ label: 'Not Reviewed', value: 0 } , { label: 'In Progress', value: 2 }]
export const REVIEW_STATUS=[{ label: 'Reviewed', value: 2 },{ label: 'Not Reviewed', value: 0 } , { label: 'In Progress', value: 1 }]
export const PO_ALERT_DATA=[{ label: 'Automatic', value: 'automatic' },{ label: 'Manual', value: 'manual' }]
export const HIGHSTOCKOUT_ALERT_DATA=[{ label: 'AdjORQ>75PerOnHand', value: 'AdjORQ>75PerOnHand' },{ label: 'Adj ORQ Based on Min ORQ', value: 'Adj ORQ Based on Min ORQ' }]

export const DATA_TABLE=[

    {
        label:'Plant',
        key:'PLANT_PLANT'
    },
    {
        label:'Material',
        key:'MATERIAL_MATERIAL'
    },
    {
        label:'Material Description',
        key:'material_description'
    },
    {
        label:'PFXPG',
        key:'PFXPG'
    },
    {
        label:'Vendor Number',
        key:'vendor_number'
    },
    {
        label:'Alert Type',
        key:'Alerts'
    },
    {
        label:'Standard Price',
        key:'standard_price'
    },
    {
        label:'$ Delta',
        key:'delta'
    },
    {
        label:'Annual Revenue ($)',
        key:'annualRevenue'
    },
    {
        label:'AvgDemand Last 12M',
        key:'AvgDemand_Last12Months'
    },
    {
        label:'AvgDemand Last 8M',
        key:'AvgDemand_Last8Months'
    },
    {
        label:'AvgDemand Last 4M',
        key:'AvgDemand_Last4Months'
    },
    {
        label:'AvgForecast Last 4M',
        key:'AvgForecast_Last4Months'
    },
    {
        label:'Last 4M Accuracy',
        key:'Last4Month_Accuracy'
    },
    {
        label:'Last 4M BIAS',
        key:'Last4Month_BIAS'
    },
    {
        label:'Lag 2 M1',
        key:'Lag2_Foreast'
    },
    {
        label:'Lag 1 M1',
        key:'Lag1_Foreast'
    },
    {
        label:'CM Open SO',
        key:'CurrentMonthOpenSalesQty'
    },
    {
        label:'CM Forecast',
        key:'forecastMonth0'
    },
    {
        label:'Adjusted CM Forecast',
        key:'AdjustedForecast0'
    },
    {
        label:'NM Forecast',
        key:'forecastMonth1'
    },
    {
        label:'Adjusted NM Forecast',
        key:'AdjustedForecast1'
    },
    {
        label:'Comments',
        key:'Comments'
    },
    {
        label:'Reviewed?',
        key:'isReviewed'
    },
    
    
]

export const CUSTOMER_LEVEL_TABLE=[

    {
        label:'Plant',
        key:'PLANT_PLANT'
    },
    {
        label:'Material',
        key:'MATERIAL_MATERIAL'
    },
    {
        label:'Sold To',
        key:'Customer_Number'
    },
    {
        label:'6 Month  Qty',
        key:'sum_qty'
    },
    {
        label:'M-6 Qty',
        key:'M_6_Qty'
    },
    {
        label:'M-5 Qty',
        key:'M_5_Qty'
    },
    {
        label:'M-4 Qty',
        key:'M_4_Qty'
    },
    {
        label:'M-3 Qty',
        key:'M_3_Qty'
    },
    {
        label:'M-2 Qty',
        key:'M_2_Qty'
    },
    {
        label:'M-1 Qty',
        key:'M_1_Qty'
    },
    
    
    
]

export const MONTH_Filter=[{ label: '0', value: 0 },{ label: '1', value: 1 },{ label: '2', value: 2 }]

export const GRAPH_TABLE=[
    {
        label:'Date',
        key:'forecastDate'
    },
    {
        label:'Forecast',
        key:'toolforecast'
    },
    {
        label:'CY Demand',
        key:'processedQty'
    },
    {
        label:'Y-1 Demand',
        key:'processedQtyLY'
    },
    {
        label:'Y-2 Demand',
        key:'processedQtyL2Y'
    }
]

export const LONGTERM_TABLE=[
    {
        label:'Material',
        key:'MATERIAL_MATERIAL'
    },
    {
        label:'Plant',
        key:'PLANT_PLANT'
    },
    {
        label:'Actual Qty 6M',
        key:'ActualQty_Last6M'
    },
    {
        label:'Post Transactional Correction (GB)',
        key:'NormalizedQty_Transactional_Last6M'
    },
    {
        label:'Post Monthly Correction (PA)',
        key:'NormalizedPAQty_Monthly_Last6M'
    },
    {
        label:'Reduction % By GB',
        key:'ReductionByGraybar'
    },
    {
        label:'Reduction % By PA',
        key:'ReductionByPA'
    },
    {
        label:'Overall Reduction %',
        key:'OverAllReduction'
    },
]

export const LONGTERM_CUSTOMER_TABLE=[
    {
        label:'Material',
        key:'MATERIAL_MATERIAL'
    },
    {
        label:'Plant',
        key:'PLANT_PLANT'
    },
    {
        label:'Customer',
        key:'Customer_Number'
    },
    {
        label:'6 Month Actual Qty',
        key:'sum_qty'
    },
    {
        label:'M-6',
        subHeader:[{
            label:'Actual Qty',
            key:'M_6_Actual_Qty'
        },{
            label:'Post Trans. Corr. (GB)',
            key:'M_6_Qty'
        },{
            label:'#Transactions',
            key:'M_6_Transactions'
        },]
    },
    {
        label:'M-5',
        subHeader:[{
            label:'Actual Qty',
            key:'M_5_Actual_Qty'
        },{
            label:'Post Trans. Corr. (GB)',
            key:'M_5_Qty'
        },{
            label:'#Transactions',
            key:'M_5_Transactions'
        },]
    }, {
        label:'M-4',
        subHeader:[{
            label:'Actual Qty',
            key:'M_4_Actual_Qty'
        },{
            label:'Post Trans. Corr. (GB)',
            key:'M_4_Qty'
        },{
            label:'#Transactions',
            key:'M_4_Transactions'
        },]
    }, {
        label:'M-3',
        subHeader:[{
            label:'Actual Qty',
            key:'M_3_Actual_Qty'
        },{
            label:'Post Trans. Corr. (GB)',
            key:'M_3_Qty'
        },{
            label:'#Transactions',
            key:'M_3_Transactions'
        },]
    }, {
        label:'M-2',
        subHeader:[{
            label:'Actual Qty',
            key:'M_2_Actual_Qty'
        },{
            label:'Post Trans. Corr. (GB)',
            key:'M_2_Qty'
        },{
            label:'#Transactions',
            key:'M_2_Transactions'
        },]
    }, {
        label:'M-1',
        subHeader:[{
            label:'Actual Qty',
            key:'M_1_Actual_Qty'
        },{
            label:'Post Trans. Corr. (GB)',
            key:'M_1_Qty'
        },{
            label:'#Transactions',
            key:'M_1_Transactions'
        },]
    },
  
]

export const INACTIVE_REBALANCE_DATA_HEADERS = [
    {
        label : 'Plant'
    },
    {
        label : 'Segment'
    },
    {
        label : 'OnHand (Qty)'
    },
    {
        label : 'OnHand ($)'
    },
    {
        label : 'Monthly Demand Qty (Avg. Last 6M)'
    },
    {
        label : 'DOH Current'
    },
    {
        label : 'DOH After Transfer'
    },
    {
        label : 'Open ERO (Qty)'
    },
    {
        label : 'Constrained Overstock (Qty)'
    },
    // {
    //     label : 'Release Next 1M'
    // },
    // {
    //     label : 'Release Next 3M'
    // },
]


export const INACTIVE_REBALANCE_DATA = [
    {
        label : 'Plant',
        key: 'ReBalancePlant'
    },
    {
        label : 'segment',
        key: 'Future_ABC_Segmentation_RB'
    },
    {
        label : 'Onhand Qty',
        key : 'Onhand_RB'
    },
    {
        label : 'Onhand $',
        key : 'OnhandDollarValue_RB'
    },
    {
        label : 'Monthly Demand Qty (Avg. Last 6M)',
        key : 'AvgMonthlyDemandLast6M_RB'
    },
    {
        label : 'Current DOH',
        key : 'DOH_RB'
    },
    {
        label : 'DOH After Transfer',
        key : 'DOHAfterTransfer'
    },
    {
        label : 'Open ERO (Qty)',
        key : 'Open_ERO_RB'
    },
    {
        label : 'Constrained Overstock Qty',
        key : 'ConstrainedOverstockQty'
    },
    // {
    //     label : 'Release Next 1M',
    //     key : ''
    // },
    // {
    //     label : 'Release Next 3M',
    //     key : ''
    // },
]

export const INACTIVE_INV_TABLE=[
    {
        label:'Material',
        key:'material'
    },
    {
        label:'Plant',
        key:'plant'
    },
    {
        label:'Category',
        key:'category'
    },
    {
        label:'Cluster',
        key:'cluster'
    },
    {
        label:'National Movement Code',
        key:'NationalMovementCode'
    },
    {
        label:'MRP Type',
        key:'MRP_Type'
    },
    {
        label:'Catalog Number',
        key:'Catalog_Number'
    },
    {
        label:'PFXPG',
        key:'PFXPG'
    },
    
    {
        label:'Vendor Name',
        key:'Vendor_Code'
    },
    {
        label:'Source',
        key:'SourcingLocation'
    },
    {
        label:'Child Locs',
        key:'NoofChildLocs'
    },
    {
        label:'Segment',
        key:'segment'
    },
    {
        label:'OnHand ($)',
        key:'onHand$'
    },
    {
        label:'OnHand (Qty)',
        key:'onHand'
    },
    {
        label : 'Eff. SS (Qty)',
        key : 'Effective_SS'
    },
    {
        label:'Open EROs',
        key:'Open_EROs'
    },
 
]

export const OVERSTOCK_INV_TABLE=[
    {
        label:'Material',
        key:'material'
    },
    {
        label:'Plant',
        key:'plant'
    },
    {
        label:'Cluster',
        key:'cluster'
    },
    {
        label:'Category',
        key:'category'
    },
    {
        label:'Segment',
        key:'segment'
    },
    {
        label:'Vendor Code/Number',
        key:'Vendor_Code'
    },
    {
        label:'MRP Type',
        key:'mrp_type'
    },
    {
        label:'MRP Group',
        key:'mrp_group'
    },
    {
        label: "OnHand ($)",
        key: "Onhand$Value",
      },
    {
        label:'OnHand (Qty)',
        key:'onHand'
    },
    {
        label:'ATP',
        key:'atp'
    },
    {
        label:'Net Inventory Qty',
        key:'Net_Inv_Qty'
    },
    {
        label:'Eff. SS (Qty)',
        key:'Effective_SS'
    },
    {
        label:'Adj. ORQ (Qty)',
        key:'adjusted_orq'
    },
    {
        label: "Constrained Overstock (Qty)",
        key: "OverStockQty",
      },

]

export const STOCKOUT_INV_TABLE=[
    {
        label:'Material',
        key:'material'
    },
    {
        label:'Plant',
        key:'plant'
    },
    {
        label:'Cluster',
        key:'cluster'
    },
    {
        label:'Category',
        key:'category'
    },
    {
        label:'Segment',
        key:'segment'
    },
    {
        label:'Vendor Code/Number',
        key:'Vendor_Code'
    },
    
]

export const PO_INV_TABLE=[
    {
        label:'Material',
        key:'Item'
    },
    {
        label:'Plant',
        key:'Locations'
    },
    {
        label:'Description',
        key:'ItemDescription'
    },
    // {
    //     label:'Category',
    //     key:'segment'
    // },
    {
        label:'Segment',
        key:'Future_ABC_Segmentation'
    },
    {
        label:'Create Date',
        key:'CreateDate'
    },
    {
        label:'Current Date',
        key:'CurrentDate'
    },
    {
        label:'Recommend Date',
        key:'RecommendedDate'
    },
    {
        label:'OnHand (Qty)',
        key:'On_Hand'
    },
    {
        label:'Request Qty',
        key:'Request_Qty'
    },
    {
        label:'Open Qty',
        key:'Open_Qty'
    },
    {
        label:'Recommended Qty',
        key:'Rcmd_Qty'
    },
    {
        label:'Recommended Qty ($)',
        key:'Rcmd_Qty$'
    },
    {
        label:'PO Number',
        key:'PO'
    },
    {
        label:'PO Line',
        key:'PO_Line'
    },
    {
        label:'Order Type',
        key:'Order_Type'
    },
    {
        label : 'Supplier Ref Number',
        key : 'Supplier_ref_number'
    },
    {
        label:'MRP Type',
        key:'MRP_Type'
    },
    {
        label:'MRP Group',
        key:'MRP_Group'
    },
    {
        label:'Vendor Name',
        key:'Vendor_Code'
    },
    
    {
        label:'PFXPG',
        key:'PFX_PG'
    },
    {
        label:'Unshipped (Qty)',
        key:'Unshipped'
    },
    {
        label : 'Avg Demand (Last 3 Months)',
        key : 'AvgMonthlyDemandLast3M'
    },
    {
        label : 'Avg Forecast (Next 3 Months)',
        key : 'AvgFutureForeastNext3M'
    },
    {
        label:'Dp Dmd OLT',
        key:'Dep_Dmd_Over_LT'
    },

    {
        label:'Calc LT',
        key:'Calculated_Lead_Time'
    },
    {

        label:'No of Plannned Locations',
        key:'No_of_Planned_Locs'
    },
    {
        label:'No of Child Locations',
        key:'No_of_Child_Locs'
    },
    

    {
        label:'Unit Cost ($)',
        key:'Unit_Cost'
    },
    {
        label:'Previous Week Comments',
        key:'PreviousComments'
    },
    {
        label:'Current Week Comments',
        key:'Comments'
    },
    {
        label:'Review Status',
        key:'isReviewed'
    },
    
]

export const tableHeaders = [
    { key: "District_Name", label: "District", width: 90 },
    { key: "Plant_Code", label: "Cluster", width: 100 },
    { key: "Plant", label: "Plant", width: 100 },
    { key: "Material", label: "Material", width: 100 },
    {
      key: "PlantSpecificMaterialStatus",
      label: "Plant Specific Material Status",
      width: 100,
    },
  
    { key: "PFXPG", label: "Supplier Prefix Purchase Group", width: 100 },
    { key: "Catalog_Number", label: "Catalog Number", width: 150 },
    { key: "Material_Description", label: "Material Description", width: 150 },
    { key: "OnHand", label: "Quantity", width: 100 },
    { key: "Cost_FOB", label: "Std. Price Per Unit ($)", width: 150 },
    { key: "Onhand$Value", label: "OnHand ($)", width: 90 },
    { key: "SKUl_Check", label: "On Last Report", width: 100 },
    { key: "Previous_Month_Status", label: "Previous Month Status", width: 130 },
    {
      key: "Previous_Resolution_Activities",
      label: "Previous Resolution Activities",
      width: 150,
    },
    { key: "Current_Month_Status", label: "Current Month Status", width: 150 },
    {
      key: "Current_Month_Resolution_Activities",
      label: "Current Month Resolution Activities",
      width: 150,
    },
    { key: "isReviewed", label: "Review Status", width: 100 },
  ];
  
  export const tableHeaders3 =  [
    { key: "Locations", label: "Location", width: 100 },
    { key: "Item", label: "Item", width: 100 },
    { key: "ItemDescription", label: "Description", width: 150 },
    { key: "CreateDate", label: "Create Date", width: 100 },
    { key: "CurrentDate", label: "Current Date", width: 100 },
    { key: "RecommendedDate", label: "Rcmd Date", width: 100 },
    { key: "ERO_Date_Diff", label: "ERO Date Diff", width: 100 },
    { key: "ERO_AGE", label: "ERO Age", width: 100 },
    { key: "Request_Qty", label: "Request Qty", width: 100 },
    { key: "Open_Qty", label: "Open Qty", width: 100 },
    { key: "Open_Qty_$", label: "Open Qty $", width: 100 },
    { key: "Rcmd_Qty", label: "Rcmd Qty", width: 100 },
    { key: "Impact", label: "Impact", width: 100 },
    { key: "PO", label: "PO", width: 100 },
    { key: "PO_Line", label: "PO Line", width: 100 },
    { key: "Supplier_Ref_Num", label: "Supplier Ref Number", width: 100 },
    { key: "Tracking_Num", label: "Tracking Number", width: 100 },
    { key: "Order_Type", label: "Order Type", width: 100 },
    { key: "MRP_Type", label: "MRP Type", width: 100 },
    { key: "Planner_Code", label: "Planner Code", width: 100 },
    { key: "MRP_Group", label: "MRP Group", width: 100 },
    { key: "MRP_Group_Expiration_Note", label: "MRP Group Expiration Note", width: 150 },
    { key: "Vendor_Code", label: "Vendor Code", width: 100 },
    { key: "PFXPG", label: "PFX-PG", width: 100 },
    { key: "Velocity_Code", label: "Velocity Code", width: 100 },
    { key: "Action_Code", label: "Action Code", width: 100 },
    { key: "Natl_Mvt_Code", label: "National Movement Code", width: 100 },
    { key: "Supply_Shortage_Past_30_Days", label: "Supply Shortage (Past 30 Days)", width: 150 },
    { key: "Stockouts_Past_30_Days", label: "Stockouts (Past 30 Days)", width: 150 },
    { key: "Unshipped", label: "Unshipped", width: 100 },
    { key: "Dep_Dmd_OLT", label: "Dep Dmd Over LT", width: 100 },
    { key: "Appr_Code", label: "Appr Code", width: 100 },
    // { key: "Cancel", label: "Cancel", width: 100 },
    // { key: "Status", label: "Status", width: 100 },
    { key: "Repl_LT", label: "Replenishment Lead Time", width: 100 },
    { key: "Calc_LT", label: "Calculated Lead Time", width: 100 },
    { key: "End_of_LT_Date", label: "End of LT Date", width: 100 },
    { key: "No_of_Children", label: "No of Child Locs", width: 100 },
    { key: "No_of_Planned_Locs", label: "No of Planned Locs", width: 100 },
    { key: "Unit_Cost", label: "Unit Cost", width: 100 },
    { key: "Cost_EA", label: "Cost EA", width: 100 },
    { key: "Onhand", label: "On Hand", width: 100 },
    // { key: "Overstock_Qty", label: "Overstock Qty", width: 100 },
    { key: "PreviousComments", label: "Previous Week Comments", width: 100 },
    { key: "Comments", label: "Current Week Comments", width: 100 },
    { key: "isReviewed", label: "Review Status", width: 100 },

];

export const tableHeader2 = [
    { key: "Locations", label: "Location", width: 100 },
    { key: "Item", label: "Item", width: 100 },
    { key: "ItemDescription", label: "Description", width: 150 },
    { key: "CreateDate", label: "Create Date", width: 100 },
    { key: "CurrentDate", label: "Current Date", width: 100 },
    { key: "RecommendedDate", label: "Rcmd Date", width: 100 },
    { key: "ERO_Date_Diff", label: "ERO Date Diff", width: 100 },
    { key: "ERO_Age", label: "ERO Age", width: 100 },
    { key: "Request_Qty", label: "Request Qty", width: 100 },
    { key: "Open_Qty", label: "Open Qty", width: 100 },
    { key: "Open_Qty_$", label: "Open Qty $", width: 100 },
    { key: "Rcmd_Qty", label: "Rcmd Qty", width: 100 },
    { key: "Impact", label: "Impact", width: 100 },
    { key: "PO", label: "PO", width: 100 },
    { key: "PO_Line", label: "PO Line", width: 100 },
    { key: "Supplier_ref_number", label: "Supplier Ref Number", width: 100 },
    { key: "Tracking_Number", label: "Tracking Number", width: 100 },
    { key: "Order_Type", label: "Order Type", width: 100 },
    { key: "MRP_Type", label: "MRP Type", width: 100 },
    { key: "Planner_Code", label: "Planner Code", width: 100 },
    { key: "MRP_Group", label: "MRP Group", width: 100 },
    { key: "MRP_Group_Expiration_Note", label: "MRP Group Expiration Note", width: 150 },
    { key: "Vendor_Code", label: "Vendor Code", width: 100 },
    { key: "PFX_PG", label: "PFX-PG", width: 100 },
    { key: "Velocity_Code", label: "Velocity Code", width: 100 },
    { key: "Action_Code", label: "Action Code", width: 100 },
    { key: "National_Movement_Code", label: "National Movement Code", width: 100 },
    { key: "Supply_Shortage_Past_30_Days", label: "Supply Shortage (Past 30 Days)", width: 150 },
    { key: "Stockout_Past_30_Days", label: "Stockouts (Past 30 Days)", width: 150 },
    { key: "Unshipped", label: "Unshipped", width: 100 },
    { key: "Dep_Dmd_Over_LT", label: "Dep Dmd Over LT", width: 100 },
    { key: "Appr_Code", label: "Appr Code", width: 100 },
    // { key: "Cancel", label: "Cancel", width: 100 },
    // { key: "Status", label: "Status", width: 100 },
    { key: "Replenishment_Lead_Time", label: "Replenishment Lead Time", width: 100 },
    { key: "Calculated_Lead_Time", label: "Calculated Lead Time", width: 100 },
    { key: "End_of_LT_Date", label: "End of LT Date", width: 100 },
    { key: "No_of_Child_Locs", label: "No of Child Locs", width: 100 },
    { key: "No_of_Planned_Locs", label: "No of Planned Locs", width: 100 },
    { key: "Unit_Cost", label: "Unit Cost", width: 100 },
    { key: "Cost_EA", label: "Cost EA", width: 100 },
    { key: "On_Hand", label: "On Hand", width: 100 },
    // { key: "Overstock_Qty", label: "Overstock Qty", width: 100 },
    { key: "PreviousComments", label: "Previous Week Comments", width: 100 },
    { key: "Comments", label: "Current Week Comments", width: 100 },
    { key: "isReviewed", label: "Review Status", width: 100 },

];

  export const tableHeader1 = [
    { key: "Material", label: "Material", width: 100 },
    { key: "Plant", label: "Plant", width: 100 },
    { key: "District_Name", label: "District", width: 100 },
    { key: "Plant_Code", label: "Cluster", width: 100 },
    // { key: "District_w_D65", label: "District w/ D65", width: 150 },
    { key: "PFXPG", label: "PFX-PG", width: 100 },
    { key: "MRP_Group", label: "MRP Group", width: 100 },
    { key: "MRP_Type", label: "MRP Type", width: 100 },
    { key: "Velocity_Code", label: "Velocity Code", width: 100 },
    {
      key: "National_Movement_Code",
      label: "National Movement Code",
      width: 150,
    },
    { key: "Catalog_Number", label: "Catalog Number", width: 150 },
    { key: "OnHand", label: "On Hand (Qty)", width: 100 },
    { key: "Rounding_Value", label: "Rounding Value", width: 100 },
    { key: "LastPODate", label: "Last PO Date", width: 100 },
    { key: "LastPONumber", label: "Last PO Number", width: 100 },
    {
      key: "ConstrainedOverstockQty",
      label: "Constrained Overstock Qty",
      width: 150,
    },
    {
      key: "ConstrainedOverstockQty$",
      label: "Constrained Overstock Qty $",
      width: 150,
    },
    {
      key: "Vendor_Code",
      label: "Vendor Code",
      width: 150,
    },
    { key: "SKUl_Check", label: "On Last Report", width: 100 },
    { key: "Previous_Month_Status", label: "Previous Month Status", width: 150 },
    {
      key: "Previous_Resolution_Activities",
      label: "Previous Resolution Activities",
      width: 150,
    },
    { key: "Current_Month_Status", label: "Current Month Status", width: 150 },
    {
      key: "Current_Month_Resolution_Activities",
      label: "Current Month Resolution Activities",
      width: 150,
    },
    { key: "isReviewed", label: "Review Status", width: 100 },
  ];
  


export const NAVBAR_FORECAST=[
    { label: 'Forecast Summary', value: '/forecast/forecast-summary',active:'forecast-summary' },
    { label: 'Alerts', value: '/forecast/forecast-alerts',active:'forecast-alerts' },
    { label: 'Forecast Overview', value: '/forecast/forecast-overview' ,active:'forecast-overview'},
]

export const NAVBAR_INVENTORY=[
    { label: 'Inventory Alerts', value: '/inventory/inventory-alerts' ,active:'inventory-alerts'},

  { label: 'Inventory Reports', value: '/inventory/inventory-reports' ,active:'inventory-reports'}

]


export const DUMMY= [
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
      },
      {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
      },
      {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
      },


];

export const DUMMYInactive= [
    {
        name: 'Cluster 1',
        uv: 3000,
        pv: 1398,
        amt: 4210,
      },
      {
        name: 'Cluster 2',
        uv: 2000,
        pv: 1000,
        amt: 4290,
      },
      {
        name: 'Cluster 3',
        uv: 2780,
        pv: 1500,
        amt: 4000,
      },


];

export const DUMMYinactiveWaterfall= [
    { 
       name: "Total Inactive Inv", 
       uv: 2400, 
       pv: 0 
    },
    {
       name: "Unaddressable inactive", 
       uv: -800, 
       pv: 2400 
    },
    {
       name: "Addressable inactive",
       uv: 1600,
       pv: 0     
    },
 
 
 ];


export const DUMMY1= [
    { 
       name: "Inactive Inventory", 
       uv: 2400, 
       pv: 0 
    },
    {
       name: "< 8 Months", 
       uv: -600, 
       pv: 2400 
    },
    {
       name: "8-12 Months",
       uv: 400,
       pv: 1800 
    },
    { name: ">12 Months", uv: 1800, pv: 0 },
  
 
 
 ];

export const SUMMARY_EXPORT=[
    {label:'Plant Code',key:'Plant_Code'},
    {label:'Plant',key:'PLANT_PLANT'},
    {label:'Material',key:'MATERIAL_MATERIAL'},
    {label:'Category (added/excluded from last run)',key:'Category'},
]

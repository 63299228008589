import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Modal,
  Notification,
  Pagination,
  Table,
  useToaster,
} from "rsuite";
import {
  INACTIVE_INV_TABLE,
  OVERSTOCK_INV_TABLE,
  STOCKOUT_INV_TABLE,
} from "../../constants/dataConstant";
import {
  getOverstockData,
  updateInventoryComments,
  updateReviewedInventoryData,
} from "../../queries/apiQueries";
import { formatNumber } from "../../utils/commonFunctions";
import { CSVLink } from "react-csv";
import { TABLEFILTER } from "../forecast/forecastDataTable";
import { useLocation } from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';
import OverStockRebalanceLevelTable from './overStockRebalanceLevelTable'
const fullText = ["segment", "category"];

const { Column, HeaderCell, Cell } = Table;
const leftStick = ["plant", "material"];
const rightStick = ["isReviewed", "RecommendedAction" , "Comments" , "RecommendedActionLostService" ,"RecommendedAction_HighDOS"];

const OverstockInventoryTable = (props: any) => {
  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );
  const { selectedCluster ,TileFilter } = props;
  const location = useLocation();
  const type = new URLSearchParams(location.search).get("type");

  const toaster = useToaster();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [exportAllData, setExportALLData] = useState<any>([]);
  const [isExport, setIsExport] = useState(false);
  const [selectedSKUL, setSelectedSKUL] = useState<any>(null);
  const exportFullRef = useRef<any>(null);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [selectedRowData, setSelectedRowData] = useState<any>([]);
  const [commentValues, setCommentValues] = useState<any>([]);
  const didMount = useRef(false);
  const [openModal, setOpenModal] = useState(false);
  const [coundtHandler, setCountHandler] = useState(0);
  const [tableHeader, setTableHeader] = useState(OVERSTOCK_INV_TABLE);

  const selectedGBPFilter = useSelector(
    (state: any) => state.CommonReducer.selectedGBPFilter
  );

  const getOverstockDataHandler = () => {
    if (isExport) {
      toaster.push(message, { placement: "topCenter" });
    } else {
      setLoading(true);
    }
    let payload = {
      ...selectedGBPFilter,
      pageNumber: pageNumber || 1,
      pageSize: 100,
      isExport: isExport,
      alertType: Number(type),
      releaseType : TileFilter
    };

    // if (selectedCluster !== "") {
    //   payload = { ...payload, clustername: [selectedCluster] };
    // }



     


    getOverstockData(payload)
      .then((response: any) => {
        if (response?.status === 200) {
          if (isExport) {
            prepareExportData(response?.data?.data);
          } else {
            setTableData(response?.data?.data);
            setCount(response?.data?.count);
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      pageNumber &&
      Object.keys(selectedGBPFilter).length !== 0 &&
      selectedRow?.length === 0
    ) {
      getOverstockDataHandler();
    }
  }, [pageNumber, selectedGBPFilter, selectedCluster , TileFilter]);

  useEffect(() => {
    if (isExport) {
      getOverstockDataHandler();
    }
  }, [isExport]);

  useEffect(() => {
    if (exportAllData?.length !== 0) {
      exportFullRef?.current?.link.click();
    }
  }, [exportAllData]);

  const prepareExportData = (data: any) => {
    if (data) {
      data?.forEach((element: any) => {
        element.OverStockatEndOfLT = formatNumber(element?.OverStockatEndOfLT);
        element.InvAtEndOfLT = formatNumber(element?.InvAtEndOfLT);
        element.maxTargetStock = formatNumber(element?.maxTargetStock);
        element.adjusted_orq = formatNumber(element?.adjusted_orq);
        element.effective_ss = formatNumber(element?.effective_ss);
        element.Total_Dmd_Within_LT_Custom = formatNumber(
          element?.Total_Dmd_Within_LT_Custom
        );
        element.open_eros = formatNumber(element?.open_eros);
        element.onHand = formatNumber(element?.onHand);
        element.mrp_group = formatNumber(element?.mrp_group);
        element.OverStock$Value = "$" + formatNumber(element?.OverStock$Value);
        element.Effective_SS = formatNumber(element?.Effective_SS);
        element.AvgMonthlyDemandLast6M = formatNumber(
          element?.AvgMonthlyDemandLast6M
        );
      });
    }

    setExportALLData(data);
  };

  const handleRowSelection = (data: any) => {
    let index = selectedRow.indexOf(data?.SKUl);
    if (index !== -1) {
      // selectedRow.splice(index, 1);
      setSelectedRow([]);
      setSelectedRowData([]);
    } else {
      setSelectedRow([data?.SKUl]);
      setSelectedRowData([data]);
    }
  };

  useEffect(() => {
    if (didMount.current) {
      if (coundtHandler >= 1) {
        props?.onRowChange(selectedRowData);
        props?.selectedRowData(selectedRowData);
      } else {
        setCountHandler(coundtHandler + 1);
      }
    } else {
      didMount.current = true;
      setCountHandler(coundtHandler + 1);
    }
  }, [selectedRowData]);

  useEffect(() => {
    let table =
      type === "1" ? [...STOCKOUT_INV_TABLE] : [...OVERSTOCK_INV_TABLE];
    if (type === "1") {
      let obj = [
        {
          label: "MRP Type",
          key: "mrp_type",
        },
        {
          label: "MRP Group",
          key: "mrp_group",
        },
        {
          label: "Current OnHand ($)",
          key: "Onhand$Value",
        },
        {
          label: "Current OnHand (Qty)",
          key: "onHand",
        },
        {
          label: "Open EROs (Qty)",
          key: "open_eros",
        },
        {
          label: "Total Dmd Within LT (Qty)",
          key: "Total_Dmd_Within_LT_Custom",
        },
        {
          label: "Inv at End of LT Current (Qty)",
          key: "InvAtEndOfLT1",
        },
        {
          label: "Eff. SS Current (Qty)",
          key: "Effective_SS",
        },
        {
          label: "Adj. ORQ Current (Qty)",
          key: "Adjusted_ORQ",
        },
        {
          label: "Monthly Demand (Avg. Last 6M)",
          key: "AvgMonthlyDemandLast6M",
        },
        {
          label: "Rep LT (Weeks) ",
          key: "Rep_LT",
        },
        {
          label: "LT Change 6M % ",
          key: "LTChange6Months",
        },
        {
          label: "Previous Month Comments",
          key: "HighDOSPreviousComment",
        },
        {
          label: "Current Month Comments",
          key: "HighDOSComment",
        },
        {
          label: "Potential Actions",
          key: "RecommendedAction_HighDOS",
        },
        {
          label: "Review Status",
          key: "isReviewed",
        },
      ];

      obj.forEach((value) => {
        table.push(value);
      });
      setTableHeader(table);
    }
    if (type === "0") {
      let obj = [
        {
          label: "Dependent demand within LT",
          key: "Dep_Dmd_OLT",
        },
        {
          label: "Independent demand within LT",
          key: "Ind_Dmd_within_LT",
        },
        {
          label: "Max Stock Level",
          key: "Max_Stock_Level",
        },
        {
          label: "Overstock $ end of LT",
          key: "OverStock$",
        },
        {
          label: "Adjusted ORQ Logic",
          key: "AdjustedORQLogic",
        },
        {
          label: "Potential Reason for Overstock",
          key: "Probable_Reason_for_Overstock",
        },
        {
          label: "Current LT ",
          key: "Rep_LT",
        },
        {
          label: "LT Change 6M % ",
          key: "AvgLeadTime",
        },

        {
          label: "Review Status",
          key: "isReviewed",
        },
      ];
      obj.forEach((value) => {
        table.push(value);
      });
      setTableHeader(table);
    }
    if (type === "2") {
      let obj1 = [
        {
          label:'Material',
          key:'material'
      },
      {
          label:'Plant',
          key:'plant'
      },
      {
          label:'Cluster',
          key:'cluster'
      },
      {
          label:'Category',
          key:'category'
      },
      {
          label:'Segment',
          key:'segment'
      },
      {
          label:'Vendor Code/Number',
          key:'Vendor_Code'
      },
      {
          label:'MRP Type',
          key:'mrp_type'
      },
      {
          label:'MRP Group',
          key:'mrp_group'
      },
      {
          label: "OnHand ($)",
          key: "Onhand$Value",
        },
      {
          label:'OnHand (Qty)',
          key:'onHand'
      },
      {
        label: "Monthly Demand (Avg. Last 3M)",
        key: "AvgMonthlyDemandLast3M",
      },
      {
          label:'ATP',
          key:'atp'
      },
      {
          label:'Net Inventory Qty',
          key:'Net_Inv_Qty'
      },
      {
          label:'Eff. SS (Qty)',
          key:'Effective_SS'
      },
      {
          label:'Adj. ORQ (Qty)',
          key:'adjusted_orq'
      },
      {
          label: "Constrained Overstock (Qty)",
          key: "OverStockQty",
        },
        {
          label: "Constrained Overstock $",
          key: "ConstrainedOverstock$",
        },
        {
          label: "Open EROs",
          key: "Open_EROs",
        },
        {
          label: "Max Stock Level",
          key: "Max_Stock_Level",
        },

   
    
        {
          label: "No. of Planned Locs",
          key: "StockingLocation",
        },
        {
          label: "Rep LT (Weeks)",
          key: "Rep_LT",
        },
        {
          label: "LT Change 6M % ",
          key: "AvgLeadTime",
        },
        {
          label: "Previous Week Comments",
          key: "PreviousComments",
        },
        {
          label: "Current Week Comments",
          key: "Comments",
        },
        {
          label: "Potential Actions",
          key: "RecommendedAction",
        },
        {
          label: "Review Status",
          key: "isReviewed",
        },
      ];
      setTableHeader(obj1);
    }
  }, [type]);

  const handleReviewChange = (data: any) => {
    updateReviewedInventoryData(data)
      .then((response) => {
        if (response?.status === 200) {
          getOverstockDataHandler();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleTextareaChange = (rowData: any, key: string, value: string) => {
    // Call the HandleResolutionChange prop with the necessary data
    console.log(key);
    
    HandleCommentonChange({
      SKUl: rowData["SKUl"],
      Comments: value, // Set the key dynamically
    });
  };


  const updateForecast = () => {
    const payload = {
      data: commentValues.map((comment:any) => ({
          SKUl: comment.SKUl,
          Comments: comment.Comments,
      })),
      alertType :  type === '1' ? "highdos" : 'overStock'
      };
  console.log(payload);
  
    updateInventoryComments(payload).then((response) =>{
      if (response?.status === 200) {
        setLoading(true);
        getOverstockDataHandler();
      }
    })
  };

  const HandleCommentonChange = (data  : any) => {
    console.log(data);
    
    const existingIndex = commentValues.findIndex(
      (item: { SKUl: any }) => item.SKUl === data.SKUl
    );
    if (existingIndex > -1) {
      // If it exists, update the existing entry
      const updatedcommentValues = [...commentValues];
      updatedcommentValues[existingIndex] = {
        ...updatedcommentValues[existingIndex],
        ...data, // Spread the updated values
      };
      setCommentValues(updatedcommentValues);
    } 
    else {
      // If it doesn't exist, add a new entry
      setCommentValues([...commentValues, data]);
    }
    console.log("Updated Resolution Data:", commentValues);
    }
    
    
  return (
    <>
      <Table
        height={windowSize?.height / 3.3}
        data={tableData ? tableData : []}
        loading={loading}
        bordered={true}
        className="border-mainGray"
        cellBordered={true}
        headerHeight={65}
        virtualized
        hover={false}
      >
        {tableHeader?.map((value) => {
          return (
            <Column
              key={value?.key}
              fullText
              // flexGrow={type === "1" ? 1 : 0}
              width={
                value?.label?.length > 18 || value?.key === "RecommendedAction" || value?.key === "RecommendedAction_HighDOS"
                  ? windowSize?.width / 10
                  : windowSize?.width / 15
              }
              fixed={
                rightStick.includes(value?.key)
                  ? "right"
                  : leftStick.includes(value?.key)
                  ? "left"
                  : false
              }
            >
              <HeaderCell
                style={{
                  backgroundColor: "black",
                  color: "white",
                }}
                className="text-0.7vw text-center border-[#333231]"
              >
                <p className="text-wrap">{value?.label}</p>
              </HeaderCell>
              <DataCell
                selectedRow={selectedRow}
                handleRowSelection={(data: any) => {
                  handleRowSelection(data);
                }}
                handleTextareaChange={handleTextareaChange}
                setOpenModal={(data :any) =>{setOpenModal(true); setSelectedSKUL(data)}}
                dataKey={value?.key}
                HandleCommentonChange ={(data : any) =>{ HandleCommentonChange(data)  }} 
                handleReviewChange={(data: any) => {
                  handleReviewChange(data);
                }}
              />
            </Column>
          );
        })}
      </Table>
      <div className="w-full flex justify-between p-2">
        <div className="w-3/12"></div>
        <div className="w-1/2 flex justify-center">
          <Pagination
            ellipsis
            boundaryLinks
            maxButtons={5}
            last
            next
            first
            size="xs"
            total={count}
            limit={100}
            layout={["pager", "skip"]}
            activePage={pageNumber}
            onChangePage={(page: any) => {
              setPageNumber(page);
            }}
          />
        </div>
        <div className="w-3/12 flex justify-end">
        <Button
            onClick={updateForecast}
            className="w-20 mr-2"
            color="green"
            appearance="primary"
          >
            Save
          </Button>
          <Button
            onClick={() => setIsExport(true)}
            className="w-20"
            color="green"
            appearance="primary"
          >
            Export
          </Button>
          <CSVLink
            data={exportAllData}
            ref={exportFullRef}
            headers={tableHeader}
            filename={type === "2" ? "Constrained_Overstock" : "High_DOS"}
            className="hidden"
          ></CSVLink>
        </div>
      </div>

      <Modal
        size={"65vw"}
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setSelectedSKUL(null);
        }}
      >
        <Modal.Header>
          <Modal.Title className="text-center text-white">
            Inventory Details For Other Active Locations{" "}
            <span className="font-bold italic text-white">
              (Within Cluster)
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Placeholder.Paragraph rows={size === 'full' ? 100 : 10} /> */}
          <OverStockRebalanceLevelTable  rowData = {selectedSKUL} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setOpenModal(false);
              setSelectedSKUL(null);
            }}
            className="bg-red-600 text-white focus:bg-red-900"
            appearance="subtle"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const DataCell = ({ rowData, dataKey,setOpenModal, ...props }: any) => {
  const location = useLocation();
  const type = new URLSearchParams(location.search).get("type");
  const changekey = [
    "OverStock$",
    "OverStockatEndOfLT",
    "InvAtEndOfLT",
    "Onhand$Value",
    "maxTargetStock",
    "adjusted_orq",
    "effective_ss",
    "Total_Dmd_Within_LT_Custom",
    "Net_Inv_Qty",
    "onHand",
    "mrp_group",
    "atp",
    "Effective_SS",
    "Adjusted_ORQ",
    "UnconstrainedOverstock$",
    "AvgLeadTime",
    "LTChange6Months",
    "Max_Stock_Level",
    "Ind_Dmd_within_LT",
    "Dep_Dmd_OLT",
    "AvgMonthlyDemandLast3M",
    "AvgMonthlyDemandLast3M",
    "InvAtEndOfLT1",
    "OverStockQty",
  ];
  const handleRowSelection = (data: any) => {
    props?.handleRowSelection({
      SKUl: data?.material + data?.plant,
      plant: data?.plant,
      material: data?.material,
      cluster: data?.cluster,
    });
  };
 
  const handleReviewClick = (data: any, check: number) => {
    props.handleReviewChange({
      SKUl: data?.SKUl,
      reviewedFlag: check,
      alertType: type === "1" ? "stockout" : "overStock",
    });
  };
  return (
    <Cell
      {...props}
      className=" text-white"
      onClick={() => {
        handleRowSelection(rowData);
      }}
      style={{
        backgroundColor:
          props?.selectedRow[0] === rowData?.material + rowData?.plant
            ? "#56544e"
            : "#1a1916",
      }}
    >
       {dataKey === "HighDOSComment"  && (
       <textarea
       onClick={(e)=>e.stopPropagation()}
       key={rowData[dataKey]}
       defaultValue={rowData[dataKey]}
       onChange={(e) => props?.handleTextareaChange(rowData, dataKey, e.target.value)}
       className="w-full text-[12px] border-[0.5px] text-black rounded-lg h-[35px] relative top-[-10px] px-2 border-gray-700 "

     />
      )}
       {dataKey === "Comments"  && (
       <textarea
       onClick={(e)=>e.stopPropagation()}
       key={rowData[dataKey]}
       defaultValue={rowData[dataKey]}
       onChange={(e) => props?.handleTextareaChange(rowData, dataKey, e.target.value)}
       className="w-full text-[12px] border-[0.5px] text-black rounded-lg h-[35px] relative top-[-10px] px-2 border-gray-700 "

     />
      )}
      
         {dataKey === "material" && (
            <p className="truncate text-0.7vw">
              {rowData[dataKey]}

            
                {rowData?.RecommendedAction?.includes('Rebalance')  ?<span
                  onClick={(e) => {
                    setOpenModal({
                      material: rowData?.material,
                      cluster: rowData?.cluster,
                    });
                    e.stopPropagation();
                  }}
                >
                  <InfoIcon
                    className="ml-2"
                    sx={{ fontSize: { xs: 15, sm: 15, md: 15, lg: 15 } }}
                  />
                </span> : <></>}
             
            </p>
          )}
           {dataKey === "ConstrainedOverstock$" && (
            <p className="truncate  text-0.7vw">
              {rowData[dataKey] !== null
                ? "$" + formatNumber((rowData[dataKey]).toFixed(0))
                : ""}
            </p>
          )}
           {dataKey === "mrp_group" && (
            <p className="truncate  text-0.7vw">
              
              {rowData[dataKey]}
             
            </p>
          )}
      {dataKey !== "isReviewed" ? (
        <>
          {!changekey?.includes(dataKey) && (
            <p className="truncate  text-0.7vw">
              {rowData[dataKey] !== null ? rowData[dataKey] : ""}
            </p>
          )}
       
          {changekey?.includes(dataKey) &&
            dataKey !== "OverStock$" &&
            dataKey !== "Onhand$Value" &&
            dataKey !== "UnconstrainedOverstock$" &&
            dataKey !== "AvgLeadTime" &&   dataKey !== "LTChange6Months" && (
              <p className="truncate  text-0.7vw">
                {rowData[dataKey] !== null
                  ? formatNumber(rowData[dataKey])
                  : ""}
              </p>
            )}
          {(dataKey === "OverStock$" ||
            dataKey === "Onhand$Value" || 
            dataKey === "UnconstrainedOverstock$") && (
            <p className="truncate  text-0.7vw">
              {rowData[dataKey] !== null
                ? "$" + formatNumber(rowData[dataKey])
                : ""}
            </p>
          )}
          {dataKey === "AvgLeadTime" && (
            <p className="truncate  text-0.7vw">
              {rowData[dataKey] !== null
                ? (Number(rowData[dataKey]) * 100).toFixed(0) + "%"
                : ""}
            </p>
          )}
          {dataKey === "LTChange6Months" && (
            <p className="truncate  text-0.7vw">
              {rowData[dataKey] !== null
                ? (Number(rowData[dataKey]) * 100).toFixed(0) + "%"
                : ""}
            </p>
          )}
        </>
      ) : (
        <ButtonToolbar className="pb-1 ">
          <ButtonGroup>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleReviewClick(rowData, 0);
              }}
              size="xs"
              appearance="ghost"
              className={`${
                rowData[dataKey] === 0
                  ? "bg-gray-500 text-white"
                  : "bg-white text-black"
              } border-black border-l-2 hover:bg-green-900 hover:text-white  text-xs`}
            >
              N
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleReviewClick(rowData, 1);
              }}
              appearance="ghost"
              size="xs"
              className={`${
                rowData[dataKey] === 1
                  ? "bg-yellow-500 text-white"
                  : "bg-white text-black"
              } border-black border-l-2  hover:bg-green-900 hover:text-white text-xs `}
            >
              IP
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleReviewClick(rowData, 2);
              }}
              appearance="ghost"
              size="xs"
              className={`${
                rowData[dataKey] === 2
                  ? "bg-green-500 text-white"
                  : "bg-white text-black"
              } border-black  hover:bg-green-900 hover:text-white  text-xs`}
            >
              Y
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      )}
    </Cell>
  );
};

const message = (
  <Notification type={"info"} header={`Exporting...`} closable>
    <p>Your file is being created. It will be download automatically</p>
  </Notification>
);

const mapDispatchToProps = (dispatch: any) => ({
  onRowChange: (data: any) => dispatch({ type: TABLEFILTER, data: data }),
});

export default connect(null, mapDispatchToProps)(OverstockInventoryTable);

import React, { useEffect, useState } from "react";
import {
  Navbar,
  Container,
  Header,
  Text,
  Grid,
  Row,
  Col,
  Divider,
  Button,
} from "rsuite";
import "../../index.css";
import Filters from "./filters";
import { useLocation, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import {
  AccountInfo,
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { callMsGraph } from "../../services/MSALGraph";
import { loginRequest } from "../../services/authConfig";
import { connect } from "react-redux";
import { appInsights } from "../../pages/router";
import {
  NAVBAR_FORECAST,
  NAVBAR_INVENTORY,
} from "../../constants/dataConstant";
import { getReleaseDate } from "../../queries/apiQueries";
export const TOKENDATA = "TOKENDATA";
export const WINDOWSIZE = "WINDOWSIZE";

const MainContainer = (props: any) => {
  const { instance, inProgress } = useMsal();
  const [graphData, setGraphData] = useState<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  
  const [RefreshDates, setRefreshDates] = useState<any>([]);

  function formatDate(date: Date | string): string {
    if (typeof date === 'string') {
      const dateObject = new Date(date);
      if (isNaN(dateObject.getTime())) {
        return 'Invalid date';
      }
      return formatDateString(dateObject);
    } else if (date instanceof Date) {
      return formatDateString(date);
    } else {
      return 'Invalid date';
    }
  }
  
  function formatDateString(date: Date): string {
    const formattedDate = new Intl.DateTimeFormat('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }).format(date);
  
    function addOrdinalSuffix(day: number) {
      if (day >= 11 && day <= 13) {
        return `${day}th`;
      }
      switch (day % 10) {
        case 1:
          return `${day}st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    }
  
    const formattedDateWithSuffix = `${addOrdinalSuffix(date.getDate())} ${formattedDate.split(' ')[1]} ${formattedDate.split(' ')[2]}`;
  
    return formattedDateWithSuffix;
  }


  const getDates = () =>{
    getReleaseDate().then((response :any) =>{
      if (response?.status === 200) {        
        setRefreshDates(response?.data?.monthlyAndWeeklyData);
      }
    })
  }



  useEffect(() => {
    if(RefreshDates?.length === 0){
      getDates()
    }
  }, [])
  

  const pathType = location?.pathname?.split("/").includes("inventory")
    ? 1
    : location?.pathname?.split("/").includes("forecast")
    ? 2
    : 0;
  const type = new URLSearchParams(location.search).get("type");

  useEffect(() => {
    if (!graphData && inProgress === InteractionStatus.None) {
      callMsGraph()
        .then((response) => {
          props?.updateToken(localStorage.getItem("accessToken"));
          setGraphData(response);
          let authHandler = localStorage.getItem("authHandler");
          if (authHandler && authHandler === "1") {
            appInsights.trackEvent({
              name: "User Login Details",
              properties: { value: response },
            });
            localStorage.setItem("authHandler", "0");
          }
          localStorage.setItem("name", response?.displayName);
        })
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount() as AccountInfo,
            });
          }
        });
    }
  }, [inProgress, graphData, instance]);

  const getDataSources = () => {
    let locationSplit = location?.pathname?.split("/");
    if(locationSplit?.includes("obsolete-materials") && type ==="2"){
      return (`Last Refreshed Date: ${formatDate(RefreshDates?.weeklyData?.[0]?.OpenPOExpediteDate)}`)
    }
    if(locationSplit?.includes("obsolete-materials") && type ==="3"){
      return (`Last Refreshed Date: ${formatDate(RefreshDates?.weeklyData?.[0]?.OpenPOVCodeDate)}`)
    }
    if(locationSplit?.includes("obsolete-materials") && type ==="0"){
      return (`Last Refreshed Date: ${formatDate(RefreshDates?.monthlyData?.[0]?.TopObsoleteRefreshDate)}`)
    }
    if(locationSplit?.includes("obsolete-materials") && type ==="1"){
      return (`Last Refreshed Date: ${formatDate(RefreshDates?.monthlyData?.[0]?.TopDNORefreshDate)}`)
    }
   
    if (locationSplit?.includes("po-inventory-managment") && type ==="0") {

      return (`Last Refreshed Date: ${formatDate(RefreshDates?.weeklyData?.[0]?.PotentialOverstockDate)}`)
      // return `( Inventory Summary 6th Dec'24, PO Data 4th Apr'24- 7th Oct'24)`;

    }
    if (
      locationSplit?.includes("overstock-inventory-managment") &&
      type === "2"
    ) {
      return (`Last Refreshed Date: ${formatDate(RefreshDates?.weeklyData?.[0]?.ConstrainedRefreshDate)}`)

      // return `( Inventory Summary 6th Dec'24, Graybar Returns 6th Dec'24 )`;

    }
    if (
      locationSplit?.includes("overstock-inventory-managment") &&
      type === "1"
    ) {
      return (`Last Refreshed Date: ${formatDate(RefreshDates?.monthlyData?.[0]?.HighDOSRefreshDate)}`)

      // return `(Inventory Summary May'24-Aug'24)`;
    }
    if (
      locationSplit?.includes("stockout-inventory-managment") &&
      type === "1"
    ) {
      return ('')

      // return `(Inventory Summary 9th Oct'24, Forecast Oct'24- Sep'25)`;

    }
    if (
      locationSplit?.includes("stockout-inventory-managment") &&
      type === "0"
    ) {
      return (`Last Refreshed Date: ${formatDate(RefreshDates?.monthlyData?.[0]?.HighStockoutRefreshDate)}`)

      // return `(Inventory Summary 9th Oct'24, Stockout Data Jul'24-Sep'24, Forecast Jul'24-Sep'24)`;

    }
    if (
      locationSplit?.includes("stockout-inventory-managment") &&
      type === "2"
    ) {
      return (`Last Refreshed Date: ${formatDate(RefreshDates?.monthlyData?.[0]?.LowDOSRefreshDate)}`)

      // return `(Inventory Summary May'24-Aug'24)`;
    }
    if (
      locationSplit?.includes("inactive-inventory-managment") &&
      type === "0"
    ) {
      return (`Last Refreshed Date: ${formatDate(RefreshDates?.monthlyData?.[0]?.PotentialInactiveRefreshDate)}`)
      
      // return `(Inventory Summary 6th Dec'24)`;
    }
    if (
      locationSplit?.includes("inactive-inventory-managment") &&
      type === "1"
    ) {
      return (`Last Refreshed Date: ${formatDate(RefreshDates?.monthlyData?.[0]?.UntaggedInactiveRefreshDate)}`)

      // return `(Inventory Summary 6th Dec'24, ZDemand 6th Dec'24 File)`;
    }
    if (
      locationSplit?.includes("inactive-inventory-managment") &&
      type === "2"
    ) {
      return (`Last Refreshed Date: ${formatDate(RefreshDates?.monthlyData?.[0]?.AgingInactiveRefreshDate)}`)

      // return `(Inventory Summary 6th Dec'24, ZDemand 6th Dec'24 File)`;
    }
    return "";
  };

  return (
    <Navbar className="bg-black px-4 py-2">
      <Grid fluid>
        <Row className="show-grid">
          <Col xs={9}>
            <div className="flex items-center">
              <Text className="font-bold text-white text-1.3vw">
                Deloitte<span className="text-mainGreen">.</span>
              </Text>
              <Divider className="h-8" vertical />
              <div className="border-2 border-white bg-white ">
                <img
                  onClick={() => {
                    navigate("/");
                  }}
                  src={"/logo.png"}
                  alt="Graybar Connect"
                  className="h-[1.8vw] cursor-pointer"
                />
              </div>
              {/* {pathType===1&&<Text className=" text-gray-400 text-1.1vw mt-1 ml-2 float-end ">Inventory Summary 4th September | Demand Data Sep'23 - Aug'24 </Text>} */}
              {/* <Text className="text-white text-2xl">Graybar</Text> */}
            </div>
          </Col>
          <Col xs={15}>
            <Text className=" text-mainGreen text-1.3vw float-end ">
              {pathType === 1 && <Divider className="h-8" vertical />}
              Hi, <span className="text-white">{graphData?.displayName}</span>
            </Text>
            <p></p>
            {pathType === 1 && (
              <div className="flex justify-end mt-[-10px]">
                <p className=" text-gray-400 text-[0.85vw] italic text-right ">

                {getDataSources()} 

                </p>

              </div>
            )}
          </Col>
        </Row>
      </Grid>
      <hr className="mt-1" />
    </Navbar>
  );
};

const NavContainer = (props: any) => {
  let location = useLocation();
  const navigate = useNavigate();

  const gotToPage = (path: string) => {
    navigate(path);
  };

  let navBarData = location?.pathname?.split("/").includes("forecast")
    ? NAVBAR_FORECAST
    : NAVBAR_INVENTORY;

  return (
    <Navbar className="bg-black px-4 pb-2">
      <div className=" flex justify-normal px-[0.2vw]">
        <div className=" flex justify-end ml-5 mr-2">
          {/* <HomeIcon
                  style={{ color: 'white'}}
                  className=" ml-1 top-[-2px] relative"
                  sx={{fontSize: { xs: 25, sm: 25, md: 30, lg: 30 } }}
                /> */}
        </div>
        <div className="grid xl:grid-cols-9 grid-cols-6 gap-1 w-full items-center ">
          {navBarData.map((element) => {
            return (
              <div className="w-full">
                <Button
                  color="green"
                  // size="sm"
                  appearance="primary"
                  onClick={() => {
                    gotToPage(element?.value);
                  }}
                  className={`w-full h-[1.5vw] font-medium text-0.95vw rounded-none ${
                    location?.pathname?.split("/").includes(element?.active)
                      ? "bg-darkGreen"
                      : "bg-inActiveGray"
                  }  hover:bg-green-900`}
                >
                  {element?.label}
                </Button>
              </div>
            );
          })}
        </div>
      </div>
      <hr className="mt-2" />
    </Navbar>
  );
};

const Headers = (props: any) => {
  let location = useLocation();

  const updateToken = (data: any) => {
    props.onTokenChange(data);
  };

  const [windowSize, setWindowSize] = useState<any>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    props.onWindowSizeChange(windowSize);
  }, [windowSize]);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Container>
      <Header>
        <MainContainer
          updateToken={(data: any) => {
            updateToken(data);
          }}
        />
        {location.pathname !== "/" && <NavContainer />}
        <Filters />
      </Header>
    </Container>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  onTokenChange: (data: any) => dispatch({ type: TOKENDATA, data: data }),
  onWindowSizeChange: (data: any) => dispatch({ type: WINDOWSIZE, data: data }),
});

export default connect(null, mapDispatchToProps)(Headers);

import React, { useEffect, useState } from "react";
import {
  Bar,
  ComposedChart,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useSelector } from "react-redux";
import { formatAmount, formatNumber } from "../../utils/commonFunctions";

import { Button, ButtonGroup, ButtonToolbar } from "rsuite";

const StockoutInventoryLineGraph = (props: any) => {
  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );

  const { lineGraphData, graphType,referenceLine } = props;
  const [graphTab, setGraphTab] = useState(graphType ? graphType : 1);
  console.log(lineGraphData,'linegraph')
  return (
    <>
      <div className="w-full">
        <div className={`flex w-full justify-between ${graphType?'mt-[2px]':''}`}>
          {graphTab !== 3 && (
            <span className="text-white font-medium text-0.95vw ">
              {graphTab === 1
                ? "On Order vs Demand Qty (Historical and Forecasted)"
                : graphTab === 2?"Inventory Trend Till Last Month (Qty)":'LT Trend'}
            </span>
          )}
          {graphTab === 3 && (
            <span className="text-white font-medium text-0.95vw ">
              {"Projected Future Inventory vs Effective SS - Qty"}
            </span>
          )}
          {(!graphType || graphType < 3 )&& (
            <ButtonToolbar className="">
              <ButtonGroup>
                <Button
                  onClick={() => {
                    setGraphTab(1);
                  }}
                  size="xs"
                  appearance="ghost"
                  className={`${
                    graphTab === 1
                      ? "bg-green-500 text-white"
                      : "bg-white text-black"
                  } border-black border-l-2 hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
                >
                  On Order vs Demand
                </Button>
                {/* <Button
                  onClick={() => setGraphTab(2)}
                  appearance="ghost"
                  size="xs"
                  className={`${
                    graphTab === 2
                      ? "bg-green-500 text-white"
                      : "bg-white text-black"
                  } border-black border-l-2  hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
                >
                  Inventory Trend
                </Button> */}
                <Button
                  onClick={() => setGraphTab(4)}
                  appearance="ghost"
                  size="xs"
                  className={`${
                    graphTab === 4
                      ? "bg-green-500 text-white"
                      : "bg-white text-black"
                  } border-black border-l-2  hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
                >
                  LT Trend
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          )}
       
        </div>
        <ResponsiveContainer
          width={"100%"}
          height={windowSize?.width / 6.5}
          className={`border-[1px] border-mainGray`}
        >
          <ComposedChart
            margin={{
              top: 0,
              right: 50,
              left: 10,
              bottom: 0,
            }}
            layout="horizontal"
            data={
              graphTab === 1 ? lineGraphData?.demand :(graphTab===2 ?lineGraphData?.inventory:(graphTab===4?lineGraphData?.repData: lineGraphData?.lineGraphData1))
            }
          >
            {graphTab<3&&<XAxis fontSize={10} stroke="#B5B5B5" dataKey="date" type="category" />}
            {graphTab===3&&<XAxis stroke="#B5B5B5" fontSize={10} scale={"point"} dataKey="name" type="category" />}
            {graphTab===4&&<XAxis stroke="#B5B5B5" fontSize={10} dataKey="date" type="category" />}
            {graphTab===3&&<ReferenceLine y={referenceLine} label="0.5 Effective SS" stroke="gray" strokeDasharray="3 3" />}
            {graphTab === 2 && (
              <YAxis
              fontSize={10}
                stroke="#B5B5B5"
                dataKey="onhand"
                tickFormatter={(value: any) =>
                  value && `${formatAmount(value)}`
                }
              />
            )}
            {graphTab !== 2 && (
              <YAxis
              fontSize={10}
                stroke="#B5B5B5"
                tickFormatter={(value: any) =>
                  value && `${formatAmount(value)}`
                }
              />
            )}

            <Tooltip content={($) => <CustomTooltip data={$} />} />
            <Legend verticalAlign="top" wrapperStyle={{fontSize: "15px"}}/>
            <ReferenceLine x="Page C" stroke="green" />
           
      
            {graphTab === 1 && (
              <Bar
                name="Forecast"
                dataKey="forecast"
                stroke="#62B5E5"
                fill="#62B5E5"
                strokeWidth={3}
                barSize={30}
                // dot={{ strokeWidth: 1, r: 3 }}
              />
            )}
                  {graphTab === 1 && (
              <Bar
                name="Demand"
                dataKey="demand"
                stroke="#9FA1A3"
                fill="#9FA1A3"
                strokeWidth={3}
                barSize={30}
                // dot={{ strokeWidth: 1, r: 3 }}
              />
            )}
             {graphTab === 1 && (
              <Line
                name="On Order"
                dataKey="purchases"
                stroke="#0097A9"
                fill="#0097A9"
                strokeWidth={3}
                dot={{ strokeWidth: 1, r: 3 }}
              />
            )}
            {/* {graphTab === 1 && (
              <Line
                dataKey="connect"
                stroke="#ffffff"
                // fill="#ffffff"
                strokeWidth={1}
                legendType="none"
                strokeDasharray="5 5"
              />
            )} */}

            {graphTab === 2 && (
              <Line
                name="On Hand (Qty)"
                dataKey="onhand"
                stroke="#0097a9"
                fill="#0097a9"
                strokeWidth={3}
                dot={{ strokeWidth: 1, r: 3 }}
              />
            )}

            {graphTab === 3 && (
              <Line
                name='Inv at the end of LT'
                dataKey="value"
                stroke="#b58d33"
                fill="#b58d33"
                strokeWidth={3}
                dot={{ strokeWidth: 1, r: 3 }}
              />
            )}
            {graphTab === 4 && (
              <Line
                name='Rep LT (Weeks)'
                dataKey="replt"
                stroke="#fff"
                fill="#fff"
                strokeWidth={3}
                dot={{ strokeWidth: 1, r: 3 }}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

const CustomTooltip = (props: any) => {
  const { active, payload } = props.data;
  if (active && payload && payload.length) {
    return (
      <>
        <div className="bg-[#353535] p-3">
          <>
            <p className="text-white text-0.95vw">
              {payload[0]?.payload?.forecastDate}
            </p>
            {payload?.map(($: any) => {
              return (
                <>
                  {$?.name!=='connect'&&$?.name!=='On Hand Last 6M'&&<p key={$?.name} className="text-white text-0.7vw">
                    {$?.name?.includes('Last')? $?.name.replace('Last 3M',''):$?.name.replace('Next 3M','')} : {formatAmount($?.value)}
                  </p>}
                  {$?.name==='On Hand Last 6M'&&<p key={$?.name} className="text-white text-0.7vw">
                    {$?.name.replace('Last 6M','')} : {formatAmount($?.value)}
                  </p>}
                </>
              );
            })}
          </>
        </div>
      </>
    );
  }

  return null;
};

export default StockoutInventoryLineGraph;

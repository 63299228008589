import React, { useEffect, useState } from "react";
import {
  Area,
  ComposedChart,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useSelector } from "react-redux";
import { formatAmount } from "../../utils/commonFunctions";

const HighLowDOSTrendLineGraph = (props: any) => {

  const {lineGraphData,graphType}=props

  const [graphTab,setGraphTab]=useState(1)

  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );


  console.log(lineGraphData);
  

  return (
    <>
      <div className="w-full">
      <div className="flex wifull justify-between">
      <span className="text-white font-medium text-0.95vw ">{'Days of Inventory Trend Last 4M'}</span>
      
        </div>
        <ResponsiveContainer
          width={"100%"}
          height={windowSize?.width / 6.5}
          className={`border-[1px] border-mainGray `}
        >
          <ComposedChart
            margin={{
              top: 0,
              right: 50,
              left: 10,
              bottom: 0,
            }}
            layout="horizontal"
            data={lineGraphData}
          >
            <XAxis fontSize={10} stroke="#B5B5B5" dataKey="date" type="category" />

            
            {/* <YAxis stroke="#B5B5B5" hide={true} fontSize={10} yAxisId="right-axis"  orientation="right"  tickFormatter={(value: any) => value && `${formatAmount(value)}`}/> */}
            <YAxis stroke="#B5B5B5"  tickFormatter={(value: any) => value && `${formatAmount(value)}`}/>

            <Tooltip content={($) => <CustomTooltip data={$} />} />
            <Legend verticalAlign="top" />
            <ReferenceLine x="Page C" stroke="green"  />
            <Line
              name="Inv at the end LT Days"
              dataKey="InvAtEndOfLT_days"
              stroke="#82ca9d"
              type="monotone"
              fill="#82ca9d"
            //  yAxisId="right-axis"
              strokeWidth={3}
              dot={{ strokeWidth: 1, r: 3 }}
            />
        <Area
              stackId="1"
              name="Effective SS Days"
              dataKey="Effective_SS_days"
              stroke="#6a8290"
              fill="#6a8290"
              fillOpacity="0.5"
              strokeWidth="0.5" 
            />       
            <Area
              stackId="1"
              name="Adjusted ORQ"
              dataKey="Adjusted_ORQ_days"
              stroke="#666666"
              fill="#666666"
              fillOpacity="0.4"
              strokeWidth="0.4" 
            />



          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

const CustomTooltip = (props: any) => {
  const { active, payload } = props.data;
  if (active && payload && payload.length) {
    return (
      <>
        <div className="bg-[#353535] p-3">
          <>
            <p className="text-white text-0.95vw">
              {payload[0]?.payload?.date}
            </p>
            {payload?.map(($: any) => {
              return (
                <>
                  {$?.name!=='connect'&&$?.name!=='On Hand Last 6M'&&<p key={$?.name} className="text-white text-0.7vw">
                    {$?.name?.includes('Last')? $?.name.replace('Last 3M',''):$?.name.replace('Next 3M','')} : {formatAmount($?.value)}
                  </p>}
                  {$?.name==='On Hand Last 6M'&&<p key={$?.name} className="text-white text-0.7vw">
                    {$?.name.replace('Last 6M','')} : {formatAmount($?.value)}
                  </p>}
                </>
              );
            })}
          </>
        </div>
      </>
    );
  }

  return null;
};

export default HighLowDOSTrendLineGraph;
